import { get } from "@/application/api";
import { AxiosResponse } from "axios";

export interface SalesOrderItem {
  articleNumber: string;
  ean: string | null;
  name: string | null;
  quantitySku: number | null;
  quantityDelivered: number | null;
  unit: string | null;
  stock: string | null;
  priceInclVat: number | null;
  priceExclVat: number | null;
  amountInclVat: number | null;
  amountExclVat: number | null;
  shipmentItems: SalesOrderItemShipmentItem[];
}

export interface SalesOrderItemShipmentItem {
  batchNumber: string | null;
  bestBeforeDate: string | null;
  quantitySku: number | null;
  serialNumbers: string | null;
}

export interface SalesOrderAddress {
  companyName: string | null;
  department: string | null;
  fullName: string | null;
  street: string | null;
  houseNumber: string | null;
  houseNumberAddition: string | null;
  postalCode: string | null;
  addressLine: string | null;
  city: string | null;
  country: string | null;
  phone: string | null;
  email: string | null;
}

export interface SalesOrderHistoryItem {
  name: string;
  date?: string;
}

export interface SalesOrder {
  id: number;
  orderNumber: string;
  orderDate: string;
  salesOrderStatus: string | null;
  source: string | null;
  paymentMethod: string | null;
  reference: string | null;
  shippingMethod: string | null;

  // Total discount
  totalDiscountInclVat: number | null;
  totalDiscountExclVat: number | null;
  totalDiscountVat: number | null;

  // Shipping costs
  shippingCostInclVat: number | null;

  // Packaging costs (incl Shipping when shippingCostInclVat is null)
  totalCostInclVat: number | null;
  totalCostExclVat: number | null;
  totalCostVat: number | null;

  // SubTotal
  amountBeforeDiscountInclVat: number | null;
  amountBeforeDiscountExclVat: number | null;
  amountBeforeDiscountVat: number | null;

  // Total
  amountInclVat: number | null;
  amountExclVat: number | null;
  amountVat: number | null;
  invoiceAddress: SalesOrderAddress;
  deliveryAddress: SalesOrderAddress;
  items: SalesOrderItem[];

  // History
  statusHistory?: SalesOrderHistoryItem[];
}

export async function SalesOrderShow({
  clientId,
  salesOrderId,
}: {
  clientId: number;
  salesOrderId: number;
}): Promise<
  AxiosResponse<{
    data: SalesOrder;
    validationErrors: { message: string; errors: Record<string, string[]> };
  }>
> {
  return get(`client/${clientId}/sales-order/${salesOrderId}`);
}
