
import Vue from "vue";
import { SalesOrderItem } from "@/modules/salesOrder/api/salesOrderShow";

export default Vue.extend({
  name: "AddressCard",
  props: {
    item: SalesOrderItem,
  },
  computed: {
    headers() {
      return [
        "batchNumber",
        "bestBeforeDate",
        "quantitySku",
        "serialNumbers",
      ].map((key) => ({
        value: key,
        text: this.$t(`salesOrder.shipmentItem.${key}`),
        sortable: false,
      }));
    },
  },
});
