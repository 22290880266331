
import Vue, { PropType } from "vue";
import { SalesOrder } from "@/modules/salesOrder/api/salesOrderShow";
import SalesOrderItemQuantityDeliveryInformation from "@/modules/salesOrder/components/SalesOrderItemQuantityDeliveryInformation.vue";

export default Vue.extend({
  name: "SalesOrderItemsCard",
  components: { SalesOrderItemQuantityDeliveryInformation },
  props: {
    order: {
      type: Object as PropType<SalesOrder | undefined>,
    },
  },
});
